import { Fragment } from "react"

const matchToNotation = (match) => {
    if (match >= 75) return 'Fusion parfaite 💕';
    if (match > 50) return 'Belle alchimie 💘';
    return 'À découvrir 🌟';
}

export const matchLevel = (matchValue) =>
    <span className="font-semibold">
        <span className={parseInt(matchValue) > 50 ? parseInt(matchValue) > 75 ? 'text-red-500' : "text-orange-500" : "text-gray-500"}> {matchToNotation(matchValue)} </span>
    </span>