import React, { useState } from 'react';

const ButtonSelect = ({ options, selectedOption, setSelectedOption }) => {

  return (
    <div className="flex flex-wrap gap-2 rounded my-2 max-w-full">
      {options.map((option, index) => 
        !option.hide && <div
          key={index}
          onClick={() => setSelectedOption(option.value)}
          className={`cursor-pointer px-3 py-1 border border-red-200 rounded-md ${selectedOption === option.value ? 'bg-red-500 text-white' : 'bg-transparent text-neutral-600'}`}
        >
          {option.label}
        </div>
      )}
    </div>
  );
};

export default ButtonSelect;
