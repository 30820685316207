export const translate = {
    'before-bac': 'Avant BAC',
    'bac-plus': 'BAC ou BAC + 1 ou BAC + 2',
    advenced: 'Études Supérieures Avancées',
    other: 'Autre',

    student: 'Étudiant(e)',
    working: 'Travailleur(euse)',
    unemployed: 'Sans emploi / En recherche d\'emploi',

    single: 'Célibataire',
    married: 'Marié',
    divorced: 'Divorcé(e)',
    widowed: 'Veuf(ve)',

    muslim: 'Musulman (e)',
    christian: 'Chrétien (e)',

    compassion:'Amour',
    cooking:'Cuisine',
    fidelity:'Fidélité',
    religion:'Religion',
    beauty:'Beauté',

}