import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../configs/Firebase";
import PulseLoader from "react-spinners/PulseLoader";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [acceptedCondition, setAcceptedCondition] = useState(false);
  const [resetMail, setResetMail] = useState(false);
  const [mailNotExist, setMailNotExist] = useState(false);

  const handleSubmit = async (event) => {
    if (validate(email)) {
      setLoading(true);
      event.preventDefault();
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate("/home");
      } catch (error) {
        console.log(error);
        setError(true);
        setLoading(false);
      }
    }
  };

  const hadleAcceptedCondition = () => {
    setAcceptedCondition(true);
  };

  const openCondition = () => {
    var url = 'https://alkhayri.com/terms-conditions';
    setTimeout(() => {
      window.open(url, "_blank");
    });
  }

  const validate = (email) => {
    if (!email) {
      return false
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return false
    }
    return true
  }

  const triggerResetEmail = () => {
    if (validate(email)) {
      sendPasswordResetEmail(auth, email).then(res => {
        setError(false);
        setResetMail(true);
      }).catch(err => {
        console.log('Error ', err)
        setMailNotExist(true);
      })
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen  bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded shadow">
        <h1 className="text-3xl font-bold text-red-500">AL KHAYRI</h1>
        <h2 className="text-xl font-bold">Connexion</h2>
        {mailNotExist && <span className='text-red-500'>
          Email inconnu
        </span>}
        <form onSubmit={handleSubmit} className="space-y-6">
          {email.length > 0 && !validate(email) && (<div className="color-danger">
            <p>Email incorrect.</p>
          </div>)}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
          <div className="mt-2">
            <input type="checkbox" id="acceptedCondition" onChange={hadleAcceptedCondition} />
            <label htmlFor="acceptedCondition" className="ml-2 text-sm font-bold">
              J'ai lu et j'accepte les <span className="underline" onClick={openCondition}>conditions générales d'utilisation.</span>
            </label>
          </div>
          <button type="submit" className="w-full py-2 text-white bg-red-500 rounded" disabled={!acceptedCondition}>
            {loading ? <PulseLoader color='white' size={15} /> : 'Se connecter'}
          </button>
        </form>
        {error && (<div className="card-login-error">
          <p className="text-red-500">Email ou mot de passe incorrect.</p>
          <p><span className='underline text-gray-600 font-semibold cursor-pointer' onClick={triggerResetEmail}>Réinitialiser mon mot de passe</span></p>
        </div>)}
        {resetMail && <span className='text-green-500'> Cliquer sur le lien reçu par mail pour changer de mot passe.</span>}


        <div className="text-center">
          <a href="/signup" className="text-gray-800">Pas de compte ? <span className="text-red-500 underline"> Créer un compte </span></a>
        </div>

      </div>
    </div>
  );
}
