import React, { useState } from 'react';
import { allCountries } from 'country-region-data';
import AsyncSelect from 'react-select/async';


const CountrySelect = ({ name, value, handleCountryChange, isMulti }) => {

    const loadCountryOptions = (inputValue) =>
        inputValue.length >= 2 && new Promise((resolve) => {
            const filtered = allCountries.filter(country =>
                country[0].toLowerCase().includes(inputValue.toLowerCase())
            ).map(country => ({
                label: country[0],
                value: country[1],
                regions: country[2].map(region => ({ label: region[0], value: region[0] }))
            }));
            resolve(filtered);
        })

    const getCountryOption = (country) => ({
        label: country[0],
        value: country[1],
        regions: country[2].map(region => ({ label: region[0], value: region[0] }))
    });

    const optionValue = () => {
        if (isMulti) {
            const selectedCountries = allCountries.filter(country => value.includes(country[1]));
            return selectedCountries && selectedCountries.map(getCountryOption);
        } else {
            const selectedCountry = allCountries.find(country => country[1] === value);
            return selectedCountry && getCountryOption(selectedCountry);
        }
    };


    return (
        <AsyncSelect
            isMulti={isMulti ?? false}
            value={optionValue()}
            placeholder="Choisir"
            name={name}
            loadOptions={loadCountryOptions}
            onChange={handleCountryChange}
            isClearable
            className="mt-1"
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => null }}
        />
    );
};

export default CountrySelect;
