import React, { useEffect, useState } from 'react';
import LeftMenu from '../../components/LeftMenu';
import { getElement } from '../../configs/http-connect';
import HomeSpace from './Home';
import ChatSpace from './Chat';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { requestForToken, auth, db } from "../../configs/Firebase";
import { onSnapshot, doc, getDoc } from "firebase/firestore";
import MatchSpace from './Match';


function Main() {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState('home');
    const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [profile, setProfile] = useState(null);
    const [receiverData, setReceiverData] = useState(null);


    const toggleRightMenu = () => {
        setIsRightMenuOpen(!isRightMenuOpen);
    };

    const selectReceiver = (value) => {
        setReceiverData(value);
        setIsRightMenuOpen(false);
    }

    const startChat = (value) => {
        setCurrentPage('chat');
        setReceiverData(value);
    }

    const getProfileData = () => {
        getElement('candidate/my-profile/').then(res => {
            setProfile(res.data);
        }).catch(err => {
            if (err.response && err.response.status === 404) {
                navigate('/form');
            } else {
                console.log('Error ::: ', err);
            }
        });
    };

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                requestForToken();
                getProfileData();
            } else {
                navigate("/signin");
            }
        });
    }, []);


    useEffect(() => {
        if (user) {
            const userDocRef = doc(db, "users", user.uid);
            const unsubscribe = onSnapshot(userDocRef, async (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const contactedUsers = docSnapshot.data().contactedUsers || [];
                    if (contactedUsers.length > 0) {
                        const contactedUserPromises = contactedUsers.map(async (contactedUserId) => {
                            const userDoc = await getDoc(doc(db, "users", contactedUserId));
                            if (userDoc.exists()) {
                                return { ...userDoc.data(), userId: userDoc.id };
                            }
                            return null;
                        });

                        const usersArray = (await Promise.all(contactedUserPromises)).filter(Boolean);
                        setUsers(usersArray);
                    } else {
                        setUsers([]);
                    }
                }
            });

            return () => unsubscribe();
        }
    }, [user]);

    const onMenuClick = (page) => {
        setCurrentPage(page);
        setIsRightMenuOpen(false);
    };

    const onChatClick = () => {
        setCurrentPage('chat');
    };

    return (
        <div className="flex h-screen w-screeen ">
            <LeftMenu profile={profile} onMenuClick={onMenuClick} onChatClick={onChatClick} isRightMenuOpen={isRightMenuOpen} toggleRightMenu={toggleRightMenu} display={currentPage} setReceiverData={selectReceiver} users={users} user={user} />
            {currentPage === 'home' && <HomeSpace toggleRightMenu={toggleRightMenu} profile={profile} onMenuClick={onMenuClick} />}
            {currentPage === 'chat' && <ChatSpace user={user} toggleRightMenu={toggleRightMenu} receiverData={receiverData} users={users} setReceiverData={selectReceiver} />}
            {currentPage === 'match' && <MatchSpace myProfile={profile} toggleRightMenu={toggleRightMenu} startChat={startChat} />}
        </div>
    );
}

export default Main;
