import React from 'react';
import { FaBars } from 'react-icons/fa';

const MenuHeader = ({ toggleRightMenu }) => {
    return (
        <header className="flex items-center justify-between p-4 py-2 bg-white shadow-md rounded mb-4">
            <div className="flex items-center">
                <FaBars className="text-gray-600 md:hidden cursor-pointer" size={24} onClick={toggleRightMenu} />
            </div>

            <div className="flex-1 text-center">
                <span className="text-3xl font-bold text-red-500">
                    ALKHAYRI
                </span>
            </div>

            <div className="w-6"></div> {/* Spacer to balance the layout */}
        </header>
    );
};

export default MenuHeader;
