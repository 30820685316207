import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

console.log('Initializing Firebase with config:', firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);

let messaging;
if ('Notification' in window && 'serviceWorker' in navigator) {
  messaging = getMessaging(firebaseApp);
  console.log('Messaging initialized');
}


export const requestForToken = async () => {
  if (messaging) {
    try {
      const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
      if (currentToken) {
        console.log('Current token for client:', currentToken);
        return currentToken;
      } else {
        console.warn('No registration token available.');
        return null;
      }
    } catch (error) {
      if (error.code === 'messaging/permission-blocked') {
        console.error('Notification permission was blocked. Please enable it in your browser settings.');
      } else {
        console.error('Error retrieving token:', error);
      }
      return null;
    }
  } else {
    console.warn('Messaging is not supported.');
    return null;
  }
};


export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    if (messaging) {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    } else {
      console.warn('Messaging is not supported.');
      reject('Messaging is not supported.');
    }
  });

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
export { firebaseApp, db, auth };