import React, { useState } from 'react';
import Select from 'react-select';
import ButtonSelectMultiple from '../../utilities/interface/ButtonSelectMultiple';
import CountrySelect from '../../utilities/interface/CountrySelect';
import ImportanceInput from './ImportanceInput';
import ButtonSelect from '../../utilities/interface/ButtonSelect';


const PartnerCriteria = ({ handleChange, handleSelectChange, formData, criteriaOption, validationErrors }) => {
    const [regionOptions, setRegionOptions] = useState([]);
    const [loadingRegions, setLoadingRegions] = useState(false);


    const handleCountryChange = (name, selectedOption) => {
        handleChange({ target: { name: name, value: selectedOption ? selectedOption.map(option => option.value) : [] } });

        if (name === 'preferredResidenceCountry') {
            const combinedRegions = selectedOption.reduce((acc, country) => {
                return acc.concat(country.regions);
            }, []);
            setRegionOptions(combinedRegions.map(region => region));
        }
    };

    const handleRegionChange = selectedOption => {
        handleChange({ target: { name: 'preferredResidenceCity', value: selectedOption ? selectedOption.map(option => option.value) : [] } });
    };

    const handleSelectAllRegions = () => {
        handleChange({ target: { name: 'preferredResidenceCity', value: formData.preferredResidenceCity.includes('all') ? [] : ['all'] } });
    };

    const handleSelectAllCountries = (name) => {
        handleChange({
            target: {
                name: name, value: formData[name].includes('all') ? [] : ['all']
            }
        });
    };

    const handleAgeRangeChange = (min, max) => {
        if (min <= max) {
            handleChange({ target: { name: 'preferredAgeRange', value: [min, max] } });
        } else {
            handleChange({ target: { name: 'preferredAgeRange', value: [min, 100] } });
        }
    };

    const handleMinAgeRangeChange = (value) => {
        handleAgeRangeChange(value, formData.preferredAgeRange[1] ?? 100)
    };

    const handleMaxAgeRangeChange = (value) => {
        handleAgeRangeChange(formData.preferredAgeRange[0] ?? 0, value)
    };

    const valueOptions = (option, vals) => {
        return option.filter(op => vals.includes(op.value))
    }

    return (
        <div className="p-8 bg-white rounded-md shadow-md">
            <div className="mb-4">
                <h2 className="text-xl font-bold">Critères du partenaire recherché</h2>
                <small className='text-red-600'>Sélectionner <span className='font-bold'>une ou plusieurs réponses</span></small>
            </div>

            <form>
                <div className="mb-5">
                    <label htmlFor="preferredAgeRange" className="block text-sm font-medium">
                        Tranche d'âge <span className="text-gray-500">*</span>
                    </label>
                    <div className="flex items-center justify-between mt-2">
                        <span>{formData.preferredAgeRange[0]} ans</span>
                        <input
                            type="range"
                            id="preferredAgeRange"
                            name="preferredAgeRange"
                            min="18"
                            max="90"
                            step="1"
                            value={formData.preferredAgeRange[0]}
                            onChange={(e) => handleMinAgeRangeChange(e.target.value)}
                            className="w-full mx-2"
                        />
                        <span>{formData.preferredAgeRange[1]} ans</span>
                        <input
                            type="range"
                            id="preferredMaxAgeRange"
                            name="preferredMaxAgeRange"
                            min="18"
                            max="90"
                            step="1"
                            value={formData.preferredAgeRange[1]}
                            onChange={(e) => handleMaxAgeRangeChange(e.target.value)}
                            className="w-full mx-2"
                        />
                    </div>
                    {validationErrors.preferredAgeRange && (
                        <p className="text-red-500">{validationErrors.preferredAgeRange}</p>
                    )}
                </div>


                <div className="flex items-center my-10">
                    <span className="mr-4 text-gray-500">Situation professionnelle <span className="text-gray-500">*</span> </span>
                    <div className="w-full border-t border-gray-300"></div>
                </div>

                <div className="mb-4">
                    <label htmlFor="preferredJobStatus" className="block text-sm font-medium">Activité</label>
                    <ButtonSelectMultiple
                        options={[
                            { value: "student", label: "Étudiant (e)" },
                            { value: "working", label: 'Travailleur (euse)' },
                            { value: "unemployed", label: 'Sans emploi / En recherche d\'emploi' },
                        ]}
                        setSelectedOption={(val) => handleSelectChange('preferredJobStatus', val)}
                        selectedOption={formData.preferredJobStatus}
                    />
                    {validationErrors.preferredJobStatus && (<p className="text-red-500">{validationErrors.preferredJobStatus}</p>)}
                </div>

                <div className="mb-4">
                    <label htmlFor="preferredEducationLevel" className="block text-sm font-medium">Niveau d'étude <span className="text-gray-500">*</span></label>
                    <ButtonSelectMultiple
                        options={[
                            { value: "before-bac", label: "Avant BAC" },
                            { value: "bac-plus", label: "BAC ou BAC + 1 ou BAC + 2" },
                            { value: "advenced", label: 'Études Supérieures Avancées' },
                            { value: "other", label: 'Autre' }
                        ]}
                        setSelectedOption={(val) => handleSelectChange('preferredEducationLevel', val)}
                        selectedOption={formData.preferredEducationLevel}
                    />
                    {validationErrors.preferredEducationLevel && (<p className="text-red-500">{validationErrors.preferredEducationLevel}</p>)}
                </div>

                <div className="mb-4">
                    <label htmlFor="preferredNationality" className="block text-sm font-medium">Pays de naissance <span className="text-gray-500">*</span></label>
                    {!formData.preferredNationality.includes('all') && (
                        <>
                            <CountrySelect
                                isMulti={true}
                                value={formData.preferredNationality}
                                name="preferredNationality"
                                handleCountryChange={(val) => handleCountryChange('preferredNationality', val)}
                            />

                        </>
                    )}
                    <div className="mt-2">
                        <input type="checkbox" checked={formData.preferredNationality.includes('all')} id="selectAllCountries" onChange={() => handleSelectAllCountries('preferredNationality')} />
                        <label htmlFor="selectAllCountries" className="ml-2 text-sm font-medium">Tous les pays</label>
                    </div>
                    {validationErrors.preferredNationality && (<p className="text-red-500">{validationErrors.preferredNationality}</p>)}
                </div>

                <div className="mb-4">
                    <label htmlFor="preferredResidenceCountry" className="block text-sm font-medium">Pays de résidence <span className="text-gray-500">*</span></label>
                    {!formData.preferredResidenceCountry.includes('all') &&
                        <CountrySelect
                            isMulti={true}
                            value={formData.preferredResidenceCountry}
                            name="preferredResidenceCountry"
                            handleCountryChange={(val) => handleCountryChange('preferredResidenceCountry', val)}
                        />
                    }
                    <div className="mt-2">
                        <input type="checkbox" checked={formData.preferredResidenceCountry.includes('all')} id="selectAllResidenceCountries" onChange={() => handleSelectAllCountries('preferredResidenceCountry')} />
                        <label htmlFor="selectAllResidenceCountries" className="ml-2 text-sm font-medium">Tous les pays</label>
                    </div>
                    {validationErrors.preferredResidenceCountry && (<p className="text-red-500">{validationErrors.preferredResidenceCountry}</p>)}
                </div>

                {!formData.preferredResidenceCountry.includes('all') && (
                    <div className="mb-4">
                        <label htmlFor="preferredResidenceCity" className="block text-sm font-medium">Ville / Région résidence <span className="text-gray-500">*</span></label>
                        {!formData.preferredResidenceCity.includes('all') && (
                            <Select
                                placeholder="Choisir..."
                                name="preferredResidenceCity"
                                isMulti
                                options={regionOptions}
                                onChange={handleRegionChange}
                                isLoading={loadingRegions}
                                isClearable
                                value={valueOptions(regionOptions, formData.preferredResidenceCity)}
                                className="mt-1"
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => null }}
                            />
                        )}
                        <div className="mt-2">
                            <input type="checkbox" id="selectAllRegions" checked={formData.preferredResidenceCity.includes('all')} onChange={handleSelectAllRegions} />
                            <label htmlFor="selectAllRegions" className="ml-2 text-sm font-medium">Toutes les régions</label>
                        </div>
                        {validationErrors.preferredResidenceCity && (<p className="text-red-500">{validationErrors.preferredResidenceCity}</p>)}
                    </div>
                )}

                <div className="flex items-center my-10">
                    <span className="mr-4 text-gray-500">Situation matrimoniale <span className="text-gray-500">*</span></span>
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="mb-4">
                    <ButtonSelectMultiple
                        options={[
                            { value: "single", label: "Célibataire" },
                            { value: "married", label: 'Marié', hide: formData.gender === 'male' },
                            { value: "divorced", label: 'Divorcé(e)' },
                            { value: "widowed", label: 'Veuf(ve)' }
                        ]}
                        setSelectedOption={(val) => handleSelectChange('preferredMaritalStatus', val)}
                        selectedOption={formData.preferredMaritalStatus}
                    />
                    {validationErrors.preferredMaritalStatus && (<p className="text-red-500">{validationErrors.preferredMaritalStatus}</p>)}
                </div>

                <div className="mb-4">
                    <ButtonSelectMultiple
                        options={[
                            { value: "0", label: "Sans enfant" },
                            { value: "1", label: 'Un enfant' },
                            { value: ">1", label: '2 enfants ou plus' },
                        ]}
                        setSelectedOption={(val) => handleSelectChange('preferredNumberOfChildren', val)}
                        selectedOption={formData.preferredNumberOfChildren}
                    />
                    {validationErrors.preferredNumberOfChildren && (<p className="text-red-500">{validationErrors.preferredNumberOfChildren}</p>)}
                </div>

                <div className="flex items-center my-10">
                    <span className="mr-4 text-gray-500">Religion<span className="text-gray-500">*</span></span>
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="mb-4">
                    <ButtonSelectMultiple
                        options={[
                            { value: "muslim", label: "Musulman (e)" },
                            { value: "christian", label: 'Chrétien (e)' },
                            { value: "other", label: 'Autre' },
                        ]}
                        setSelectedOption={(val) => handleSelectChange('preferredReligions', val)}
                        selectedOption={formData.preferredReligions}
                    />
                    {validationErrors.preferredReligions && (<p className="text-red-500">{validationErrors.preferredReligions}</p>)}
                </div>

                <div className="flex items-center my-10">
                    <span className="mr-4 text-gray-500">Préférence</span>
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="mb-4">
                    <ImportanceInput handleSelectChange={handleSelectChange} criteria={criteriaOption} />
                </div>
            </form>
        </div>

    );
};

export default PartnerCriteria;
