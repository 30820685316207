import React, { useState } from 'react';

const SecondaryInfo = ({ otherInformations, formData, handleSelectChange, validationErrors }) => {

  const formatDataOption = (id) => {
    const response = formData.otherInformationResponses?.find(res => res.otherInformationId == id);
    return response?.value ?? '';
  }

  const onResponseChange = (id, text) => {
    var updatedOtherInformations = [...formData.otherInformationResponses];

    if (formData.otherInformationResponses.find(otherInfo => otherInfo.otherInformationId === id)) {
      updatedOtherInformations = formData.otherInformationResponses.map(otherInfo =>
        otherInfo.otherInformationId === id ? { ...otherInfo, value: text } : otherInfo
      );
    } else {
      updatedOtherInformations = [...updatedOtherInformations, { otherInformationId: id, value: text }]
    }
    handleSelectChange('otherInformationResponses', updatedOtherInformations)
  };


  return (
    <div className="p-8 bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-bold mb-4">Mieux vous connaitre</h2>
      <form>
        <div className="mb-4">
          <label htmlFor={`bio`} className="block text-sm font-medium">
            Bio <small className='mx-2'>Exprimez vous </small>
            </label>
          <textarea
            id={`bio`}
            name={`bio`}
            placeholder="Ex: 🌟 Je suis une personne travailleuse, respectueuse et sociable. 🙌 Je recherche quelqu'un qui incarne l'intégrité et les valeurs religieuses, car j'accorde une grande importance aux principes religieux. (Kou bah ame diné) 🌿"
            onChange={(e) => handleSelectChange('bio', e.target.value)}
            value={formData.bio}
            className="h-20 mt-1 p-2 block w-full border rounded-md"
          />
        </div>

        {otherInformations?.map((otherInf) => (
          <div key={otherInf.id} className="mb-4">
            <label htmlFor={`question-${otherInf.id}`} className="block text-sm font-medium">{otherInf.question_name}</label>
            <textarea
              id={`question-${otherInf.id}`}
              name={`question-${otherInf.id}`}
              placeholder='Votre réponse'
              onChange={(e) => onResponseChange(otherInf.id, e.target.value)}
              value={formatDataOption(otherInf.id)}
              className="h-20 mt-1 p-2 block w-full border rounded-md"
            />
          </div>
        ))}
        {validationErrors.otherInformationResponses && <span className="text-red-500">{validationErrors.otherInformationResponses}</span>}
      </form>
    </div>
  );
};

export default SecondaryInfo;
