import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Conditions Générales d'Utilisation</h1>
      <p>Bienvenue sur Alkhayri, une plateforme dédiée aux adultes ayant des projets de mariage sérieux. En accédant à notre plateforme et en l'utilisant, vous acceptez de vous conformer aux présentes Conditions Générales d'Utilisation (CGU). Veuillez les lire attentivement.</p>
      
      <h2 className="text-xl font-semibold mt-4">1. Acceptation des Conditions</h2>
      <p>En utilisant Alkhayri, vous acceptez les présentes CGU. Si vous n'êtes pas d'accord avec ces conditions, veuillez ne pas utiliser notre plateforme.</p>

      <h2 className="text-xl font-semibold mt-4">2. Objectif de la Plateforme</h2>
      <p>Alkhayri est une plateforme de rencontre destinée exclusivement aux adultes recherchant un mariage. Toute autre utilisation est strictement interdite.</p>

      <h2 className="text-xl font-semibold mt-4">3. Inscription et Profil Utilisateur</h2>
      <p>Pour utiliser Alkhayri, vous devez :</p>
      <ul className="list-disc list-inside ml-4">
        <li>Remplir le formulaire d'inscription avec des informations exactes et complètes.</li>
        <li>Maintenir la confidentialité de vos identifiants de connexion.</li>
        <li>Accepter que votre profil soit envoyé à des personnes susceptibles de vous correspondre.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">4. Confidentialité et Sécurité</h2>
      <p>
        Aucun numéro de téléphone ne sera fourni sur les profils pour respecter la confidentialité.
        Les communications initiales se feront par email ou via le chat disponible sur la plateforme.
        L'échange de numéros de téléphone est une décision personnelle.
        Ne divulguez pas d'informations financières personnelles ou sensibles lors des discussions.
      </p>

      <h2 className="text-xl font-semibold mt-4">5. Comportement des Utilisateurs</h2>
      <p>Il est interdit de :</p>
      <ul className="list-disc list-inside ml-4">
        <li>Montrer des signes de richesse excessive ou de difficultés financières.</li>
        <li>Transmettre les profils reçus à des amis non-inscrits à Alkhayri.</li>
        <li>Ne pas respecter les autres utilisateurs et leurs données personnelles.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-4">6. Vérification et Exclusion</h2>
      <p>Nous effectuons des vérifications régulières. Toute tentative de transgression des règles entraînera l'exclusion immédiate du projet.</p>

      <h2 className="text-xl font-semibold mt-4">7. Correspondance et Suivi</h2>
      <p>Vous recevrez des profils de personnes pouvant vous correspondre. Si les profils ne vous conviennent pas, vous pouvez nous contacter par email pour un nouvel essai. Il est possible que vous soyez contacté par des personnes qui ne figurent pas sur la liste de profils envoyés.</p>

      <h2 className="text-xl font-semibold mt-4">8. Responsabilité</h2>
      <p>Alkhayri décline toute responsabilité en cas de litige entre utilisateurs. Chaque utilisateur est responsable de ses interactions et des décisions qu'il prend sur la plateforme.</p>

      <h2 className="text-xl font-semibold mt-4">9. Modifications des CGU</h2>
      <p>Alkhayri se réserve le droit de modifier les présentes CGU à tout moment. Les modifications seront publiées sur notre site et entreront en vigueur immédiatement après leur publication.</p>

      <h2 className="text-xl font-semibold mt-4">10. Contact</h2>
      <p>Pour toute question ou demande d'information supplémentaire, veuillez nous contacter par email.</p>

      <p>En utilisant Alkhayri, vous acceptez de respecter ces conditions et de contribuer à maintenir un environnement respectueux et sécurisé pour tous les utilisateurs.</p>

      <p>Merci de votre confiance et bonne chance dans votre recherche de l'âme sœur avec Alkhayri.</p>
    </div>
  );
};

export default TermsAndConditions;
