import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, updateDoc, doc, arrayUnion } from "firebase/firestore";
import { db } from "../../configs/Firebase";
import useChatScroll from "../../utilities/useChatScroll";
import { FaArrowRight, FaRegUser } from "react-icons/fa";
import UsersComponent from "../../components/UsersComponent";
import MenuHeader from "../../components/MenuHeader";
import PulseLoader from "react-spinners/PulseLoader";
import ProfileDetail from "../../components/ProfileDetail";
import { getElement } from "../../configs/http-connect";

export default function ChatSpace({ user, receiverData, toggleRightMenu, users, setReceiverData }) {
    const [chatMessage, setChatMessage] = useState("");
    const [allMessages, setAllMessages] = useState([]);
    const [profile, setProfile] = useState([]);
    const [loading, setLoading] = useState(false);
    const [displayProfileDetails, setProfileDisplayDetails] = useState(false);

    const navigate = useNavigate();
    const chatContainerRef = useChatScroll(allMessages); // Use the custom hook


    const getProfileData = () => {
        getElement('candidate/profile_by_uid/' + receiverData.userId).then(res => {
            setProfile(res.data);
            setProfileDisplayDetails(true);
        }).catch(err => {
            console.log('Error ::: ', err);
        });
    };


    useEffect(() => {
        if (user && receiverData) {
            const messagesRef = collection(db, "users", user.uid, "chatUsers", receiverData.userId, "messages");
            const q = query(messagesRef, orderBy("timestamp", "asc"));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const newMessages = [];
                querySnapshot.forEach((doc) => {
                    newMessages.push({ id: doc.id, ...doc.data() });
                    if (!doc.data().read && doc.data().messageUserId !== user.uid) {
                        updateDoc(doc.ref, { read: true });
                    }
                });
                setAllMessages(newMessages);
            });

            return () => unsubscribe();
        }
    }, [user, receiverData]);


    const sendMessage = async () => {
        if (chatMessage.trim() !== "" && receiverData) {
            try {
                const messageData = {
                    username: user.displayName,
                    messageUserId: user.uid,
                    message: chatMessage,
                    timestamp: serverTimestamp(),
                    read: false
                };

                const userChatRef = collection(db, "users", user.uid, "chatUsers", receiverData.userId, "messages");
                const receiverChatRef = collection(db, "users", receiverData.userId, "chatUsers", user.uid, "messages");

                await addDoc(userChatRef, messageData);
                await addDoc(receiverChatRef, messageData);

                // Update contactedUsers field for both users
                const userDocRef = doc(db, "users", user.uid);
                const receiverDocRef = doc(db, "users", receiverData.userId);

                await updateDoc(userDocRef, {
                    contactedUsers: arrayUnion(receiverData.userId)
                });

                await updateDoc(receiverDocRef, {
                    contactedUsers: arrayUnion(user.uid)
                });

                setChatMessage('');
            } catch (error) {
                console.error("Error sending message: ", error);
            }
        }
    };


    return (
        <div className="bg-gray-100 h-screen pt-0 lg:p-10 lg:pt-0 w-full overflow-auto">
            <div className="w-full md:flex md:justify-center p-2">

                {/* Chat area */}
                <div className="md:w-[620px] overflow-hidden">
                    <MenuHeader toggleRightMenu={toggleRightMenu} />
                    {displayProfileDetails ? <ProfileDetail profile={profile} setDisplayDetails={setProfileDisplayDetails} readOnly={true}/> :
                        <Fragment>
                            {receiverData ? (
                                <>
                                    <div className="flex items-center justify-between border-b border-gray-300 pb-2">
                                        <div className="flex items-center space-x-4">
                                            <div>
                                                <h3 className="text-lg font-bold flex items-center cursor-pointer" onClick={getProfileData}>
                                                    <FaRegUser className="mr-3" color='gray' />
                                                    {receiverData.username} {loading && <PulseLoader color='red' size={15} />}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-4">
                                            <button className="p-2 text-gray-500">
                                                <i className="fas fa-cog"></i>
                                            </button>
                                            <button className="p-2 text-gray-500">
                                                <i className="fas fa-phone-alt"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4 space-y-4">
                                        {receiverData.email && <div className="w-full flex justify-center">
                                            <button type="button" className="border bg-gray-50 border-gray text-gray px-2 py-2 rounded-md flex items-center my-2">
                                                <a href={"mailto:" + receiverData.email} className="flex items-center">Contactez {receiverData.username} par mail <FaArrowRight color="red" className="ml-4" /> </a>
                                            </button>
                                        </div>}


                                        {allMessages.map(({ id, messageUserId, message, timestamp }) => (
                                            <div
                                                key={id}
                                                className={`flex ${messageUserId === user?.uid ? "justify-end" : "justify-start"}`}
                                            >
                                                <div className={`max-w-xs p-2 rounded ${messageUserId === user?.uid ? "bg-red-950 text-white" : "bg-gray-300"}`}>
                                                    {message}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex items-center p-1">
                                        <input
                                            type="text"
                                            placeholder="Type your message"
                                            value={chatMessage}
                                            onChange={(e) => setChatMessage(e.target.value)}
                                            className="flex-grow p-2 border border-gray-300 rounded"
                                        />
                                        <button
                                            onClick={sendMessage}
                                            className="p-2 ml-2 text-white bg-red-950 rounded"
                                        >
                                            Envoyez
                                        </button>
                                    </div>
                                </>
                            ) : (<>
                                <div>
                                    <span className="text-xl text-gray-800">Espace de discussion</span>
                                    <p className="text-gray-800 my-3">Contacts</p>
                                    <UsersComponent
                                        users={users}
                                        setReceiverData={setReceiverData}
                                        currentUserId={user?.uid}
                                    />
                                </div>
                            </>)}
                        </Fragment>}
                </div>


            </div>
        </div>
    );
}
