import React, { useState } from 'react';
import { FaBirthdayCake, FaFlag, FaPrayingHands, FaMapMarkerAlt, FaCity, FaRing, FaGraduationCap, FaChild, FaCheckCircle, FaArrowRight, FaUserEdit, FaComments } from 'react-icons/fa';
import UsersComponent from './UsersComponent';
import { getAge } from '../utilities/calcul';
import { GoAlertFill } from 'react-icons/go';
import { translate } from '../utilities/lng';
import { useNavigate } from 'react-router-dom';

function LeftMenu({ profile, onMenuClick, onChatClick, isRightMenuOpen, toggleRightMenu, user, users, setReceiverData, display }) {

    const navigate = useNavigate();

    return (
        <div className={`bg-gray-100 rounded-lg shadow-md min-h-screen md:w-[380px] md:relative ${isRightMenuOpen ? 'w-screen fixed z-10 block' : 'hidden'} md:block`}>
            <div className="flex flex-col items-center h-screen overflow-auto p-6">
                <h2 className="text-2xl font-semibold">{profile ? profile.name : "Nom"} {getAge(profile?.birth_date)}</h2>
                <p className="text-gray-600">{profile ? profile.profession : "Statut professionnel"}</p>
                {profile?.payed ? <button type="button" className="border bg-white border-gray text-green px-4 py-1 rounded-md flex items-center my-2 ">
                    <FaCheckCircle color="green" className="mr-2" /> Active
                </button> : <button type="button" className="border bg-white border-red text-green px-4 py-1 rounded-md flex items-center my-2 ">
                    <GoAlertFill color="red" className="mr-2" /> Inactif
                </button>}

                <div className="mt-4 w-full">
                    <button type="button" onClick={() => onMenuClick('home')} className="shadow-sm border w-full bg-gray-50 border-gray text-gray px-2 py-2 rounded-md flex items-center my-2">
                        Acceuil
                    </button>
                    {profile?.payed && <button type="button" onClick={() => onMenuClick('match')} className="shadow-sm border w-full bg-gray-50 border-gray text-gray px-2 py-2 rounded-md flex items-center my-2">
                        Mes matches <FaArrowRight color="red" className="ml-4" />
                    </button>}
                    {(profile?.payed || profile?.unblocked) && <button type="button" onClick={onChatClick} className="shadow-sm border w-full bg-gray-50 border-gray text-gray px-2 py-2 rounded-md flex items-center my-2">
                        <FaComments className="mr-1" size={20} /> Messages
                    </button>}
                    {display === 'chat' && <div className={`w-full`}>
                        <UsersComponent
                            users={users}
                            setReceiverData={setReceiverData}
                            currentUserId={user?.uid}
                        />
                    </div>}
                    <h3 className="my-4 flex items-center">Mon profile</h3>
                    <hr className="my-4" />
                    {profile ? (
                        <div className="space-y-4">
                            <div className="my-1">
                                <p className="text-gray-500 text-sm font-semibold">Bio</p>
                                <p>{profile.bio}</p>
                            </div>


                            <div className="flex items-center">
                                <FaPrayingHands className="text-red-500 mr-3" />
                                <span>{translate[profile.religion]}</span>
                            </div>
                            <div className="flex items-center">
                                <FaMapMarkerAlt className="text-red-500 mr-3" />
                                <span>{profile.residence_country}</span>
                            </div>
                            <div className="flex items-center">
                                <FaCity className="text-red-500 mr-3" />
                                <span>{profile.residence_city}</span>
                            </div>
                            <div className="flex items-center">
                                <FaRing className="text-red-500 mr-3" />
                                <span><strong>{translate[profile.marital_status]} </strong></span>
                            </div>
                            <div className="flex items-center">
                                <FaGraduationCap className="text-red-500 mr-3" />
                                <span>{translate[profile.education_level]}</span>
                            </div>
                            <div className="flex items-center">
                                <FaChild className="text-red-500 mr-3" />
                                <span><strong>{profile.number_of_children} enfants</strong> </span>
                            </div>

                            <button type="button" onClick={() => navigate('/form')} className="shadow-sm border w-full bg-gray-50 border-gray text-gray px-2 py-2 rounded-md flex items-center my-2">
                                <FaUserEdit color="red" className="mr-3" /> Modifier mon profile
                            </button>
                        </div>


                    ) : (
                        <p>Chargement des détails du profil...</p>
                    )}
                </div>

            </div>

        </div>
    );
}

export default LeftMenu;
