import React, { useEffect, useState } from 'react';
import love from '../../assets/img/love.svg';
import { FaLongArrowAltRight } from 'react-icons/fa';
import PrimaryInfo from '../../components/form/PrimaryInfo';
import PartnerCriteria from '../../components/form/PartnerCriteria';
import SecondaryInfo from '../../components/form/SecondaryInfo';
import { createElement, getElement } from '../../configs/http-connect';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../configs/Firebase';
import { onAuthStateChanged } from 'firebase/auth';
import PulseLoader from 'react-spinners/PulseLoader';
import { mapCriteria, mapOtherInformation, mapOtherinformtion, mapProfile } from '../../utilities/mapingForm';

const CandidateForm = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [user, setUser] = useState(null);
    const [acceptedCondition, setAcceptedCondition] = useState(false);
    const [otherInformations, setOtherInformations] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(false);

    const criteriaOption = [
        { value: 'compassion', label: 'Amour' },
        { value: 'cooking', label: 'Cuisine' },
        { value: 'fidelity', label: 'Fidélité' },
        { value: 'religion', label: 'Religion' },
        { value: 'beauty', label: 'Beauté' },
    ];
    const [profileCriteria, setProfileCriteria] = useState([]);

    const [formData, setFormData] = useState({
        // STEP 1
        name: '',
        bio: '',
        birthDate: '',
        gender: '',
        birthCountry: '',
        residenceCountry: '',
        residenceCity: '',
        religion: '',
        educationLevel: '',
        profession: '',
        maritalStatus: '',
        numberOfChildren: 0,
        profileCriteria: {
            religion: 50,
            beauty: 50,
            fidelity: 50,
            compassion: 50,
            cooking: 50,
        },

        // STEP 2
        partnerCriteria: {
            religion: 50,
            beauty: 50,
            fidelity: 50,
            compassion: 50,
            cooking: 50,
        },
        preferredAgeRange: [],
        preferredNationality: [],
        preferredReligions: [],
        preferredResidenceCountry: [],
        preferredResidenceCity: [],
        preferredJobStatus: [],
        preferredMaritalStatus: ['single'],
        preferredEducationLevel: [],
        preferredNumberOfChildren: ['0'],
        jobStatus: '',
        otherInformationResponses: []
    });

    const fetchData = async (setFormData, setOtherInformations) => {
        try {
            // Fetch profile data
            const profileRes = await getElement('candidate/my-profile/');
            if (profileRes.data) {
                const profileData = mapProfile(profileRes.data);
                setFormData(prevData => ({
                    ...prevData,
                    ...profileData,
                }));
                await fetchOtherInformation(profileRes.data.id, setFormData);
            }

            // Fetch partner criteria
            const criteriaRes = await getElement('candidate/my_criteria/');
            if (criteriaRes.data) {
                const criteriaData = mapCriteria(criteriaRes.data);
                setFormData(prevData => ({
                    ...prevData,
                    ...criteriaData,
                }));
            }

            // Fetch other questions
            const questionsRes = await getElement('candidate/other-informations/');
            if (questionsRes.data) {
                setOtherInformations(questionsRes.data.results);
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    };

    // Separate function to fetch and map other information based on profile ID
    const fetchOtherInformation = async (profileId, setFormData) => {
        try {
            const otherInfoRes = await getElement(`candidate/other_information_profile/${profileId}`);
            if (otherInfoRes.data) {
                console.log(' otherInfoRes.data   --- ', otherInfoRes.data);
                console.log(' otherInfoRes.profileId   --- ', profileId);
                const otherInfoData = mapOtherInformation(otherInfoRes.data);
                setFormData(prevData => ({
                    ...prevData,
                    ...otherInfoData,
                }));
            }
        } catch (err) {
            console.error('Error fetching other information:', err);
        }
    };

    // Usage example
    useEffect(() => {
        fetchData(setFormData, setOtherInformations);
    }, []);



    const getOtherQuestions = () => {
        getElement('candidate/other-informations/').then(res => {
            setOtherInformations(res.data.results);
        }).catch(err => {
            console.log('Error ::: ', err);
        });
    };

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                getOtherQuestions();
            } else {
                navigate("/signin");
            }
        });
    }, [navigate]);

    const validateDate = (date) => {
        const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
        return regex.test(date);
    };

    const validateStep = (currentStep) => {
        let errors = {};
        if (currentStep === 1) {
            if (!formData.name) errors.name = 'Le nom est requis';
            if (!validateDate(formData.birthDate)) errors.birthDate = 'Le format de la date est incorrect JJ/MM/AAAA';
            if (!formData.gender) errors.gender = 'Le sexe est requis';
            if (!formData.birthCountry) errors.birthCountry = 'Le pays de naissance est requis';
            if (!formData.residenceCountry) errors.residenceCountry = 'Le pays de résidence est requis';
            if (!formData.residenceCity) errors.residenceCity = 'La ville de résidence est requise';
            if (!formData.religion) errors.religion = 'La religion est requise';
            if (!formData.educationLevel) errors.educationLevel = 'Le niveau d\'éducation est requis';
            if (!formData.maritalStatus) errors.maritalStatus = 'L\'état civil est requis';
        } else if (currentStep === 2) {
            if (formData.preferredAgeRange.length === 0) errors.preferredAgeRange = 'La tranche d\'âge préférée est requise';
            if (formData.preferredNationality.length === 0) errors.preferredNationality = 'La nationalité préférée est requise';
            if (formData.preferredReligions.length === 0) errors.preferredReligions = 'Les religions préférées sont requises';
            if (formData.preferredResidenceCountry.length === 0) errors.preferredResidenceCountry = 'Le pays de résidence préféré est requis';
            if (!formData.preferredResidenceCountry.includes('all') && formData.preferredResidenceCity.length === 0) errors.preferredResidenceCity = 'La ville de résidence préférée est requise';
            if (formData.preferredJobStatus.length === 0) errors.preferredJobStatus = 'Le statut professionnel préféré est requis';
            if (formData.preferredEducationLevel.length === 0) errors.preferredEducationLevel = 'Le niveau d\'éducation préféré est requis';
            if (formData.preferredNumberOfChildren.length === 0) errors.preferredNumberOfChildren = 'Le nombre d\'enfants préféré est requis';
            if (!formData.jobStatus) errors.jobStatus = 'Le statut professionnel est requis';
        }
        else if (currentStep === 3) {
            if (formData.otherInformationResponses.length <= 6) errors.otherInformationResponses = 'Veillez répondre plus de 6 questions au minimum';
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const nextStep = () => {
        console.log(' setValidationErrors ', validationErrors)
        if (validateStep(step)) {
            setStep(prevStep => prevStep + 1);
        }
    };

    const prevStep = () => {
        setStep(prevStep => prevStep - 1);
    };

    const updatePreferred = (name, value) => {
        if (name === 'nationality') {
            setFormData({
                ...formData,
                preferredNationality: [...formData.preferredNationality, value]
            });
        }
        if (name === 'residenceCountry') {
            setFormData({
                ...formData,
                preferredResidenceCountry: [...formData.preferredResidenceCountry, value]
            });
        }

        if (name === 'religions') {
            setFormData({
                ...formData,
                preferredReligions: [...formData.preferredReligions, value]
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        updatePreferred(name, value);
    };

    const handleSelectChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };



    const handleCriteriaChange = (type, name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [type]: {
                ...prevData[type],
                [name]: value
            }
        }));
    };

    const formatDateForBackend = (date) => {
        const [day, month, year] = date.split('/');
        return `${year}-${month}-${day}`;
    };

    const handleSubmit = async (e) => {
        if (validateStep(step)) {
            setLoading(true);
            const dataToSave = {
                ...formData,
                preferredResidenceCity: formData.preferredResidenceCountry.includes('all') ? ['all'] : formData.preferredResidenceCity,
                birthDate: formatDateForBackend(formData.birthDate),
                profileCriteria: {
                    religion: profileCriteria.includes('religion') ? 70 : 30,
                    beauty: profileCriteria.includes('beauty') ? 70 : 30,
                    fidelity: profileCriteria.includes('fidelity') ? 70 : 30,
                    compassion: profileCriteria.includes('compassion') ? 70 : 30,
                    cooking: profileCriteria.includes('cooking') ? 70 : 30,
                },
            };
            e.preventDefault();
            createElement('candidate/create-profile/', dataToSave).then(res => {
                navigate('/home');
            }).catch(err => {
                console.error('There was an error creating the profile!', err);
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const FormWrapper = () => {
        switch (step) {
            case 1:
                return <PrimaryInfo profileCriteria={profileCriteria} setProfileCriteria={setProfileCriteria} handleChange={handleChange} formData={formData} handleSelectChange={handleSelectChange} criteriaOption={criteriaOption} validationErrors={validationErrors} />;
            case 2:
                return <PartnerCriteria handleChange={handleChange} formData={formData} handleSelectChange={handleSelectChange} criteriaOption={criteriaOption} validationErrors={validationErrors} />;
            case 3:
                return <SecondaryInfo otherInformations={otherInformations} handleSelectChange={handleSelectChange} formData={formData} validationErrors={validationErrors} />;
            default:
                return null;
        }
    };

    const hadleAcceptedCondition = () => {
        setAcceptedCondition(true);
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-neutral-600">
            <div className='w-screen md:w-[630px] bg-white mb-5 rounded-xl'>
                <img src={love} className="object-cover w-[630px] h-[160px] rotate-[0deg] rounded-xl mt-[0px]" alt="Coeur" />
                {FormWrapper()}
            </div>
            <div className="flex justify-between  mb-10 w-screen md:w-[620px] px-3">
                {step === 3 && (
                    <div className="mt-2">
                        <input type="checkbox" id="acceptedCondition" onChange={hadleAcceptedCondition} />
                        <label htmlFor="acceptedCondition" className="ml-2 text-sm font-bold">
                            J'accepte de partager les informations que j'ai fournies sur ce formulaire.
                        </label>
                    </div>
                )}
            </div>
            <div className="flex justify-between  mb-10 w-screen md:w-[620px] px-3">
                {step > 1 ? (
                    <button type="button" onClick={prevStep} className="border border-gray text-gray px-4 py-1 rounded-md">
                        Précédent
                    </button>
                ) : (
                    <div />
                )}
                {step < 3 && (
                    <button type="button" onClick={nextStep} className="bg-white border border-red-200 text-red-600 px-4 py-1 rounded-md flex items-center">
                        Suivant <FaLongArrowAltRight className="ml-2" />
                    </button>
                )}
                {step === 3 && acceptedCondition && (
                    <button type="button" onClick={handleSubmit} className="bg-white border border-red-200 text-red-600 px-4 py-1 rounded-md flex items-center">
                        {loading ? <PulseLoader color='red' size={10} /> : <span className='flex items-center'> Terminer <FaLongArrowAltRight className="ml-2" />  </span>}
                    </button>
                )}
            </div>
        </div>
    );
};

export default CandidateForm;
