import axios from 'axios';
import { auth } from './Firebase';
import tokenService from './token.service';

const host = 'https://backend.alkhayri.com';
//const host = 'http://127.0.0.1:8000';
/* Permet de recuperer la liste d'un element
*
*/

export const Header = {
    'Accept': "application/json",
    "Content-Type": "application/json",
}

export const HeaderFile = {
    'Accept': "application/json, text/plain,application/zip, image/png, image/jpeg, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "Content-Type": "multipart/form-data",
}


const axiosInstance = axios.create({
    baseURL: host,
});

const renewToken = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            unsubscribe();
            if (user) {
                try {
                    const token = await tokenService.getToken(user);
                    resolve(token);
                    console.log('renew token ', token);
                } catch (error) {
                    reject(error);
                }
            } else {
                resolve(null);
            }
        });
    });
};

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        console.log(' token ', token);
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response && error.response.status === 401) {
            const newToken = await renewToken();
            if (newToken) {
                localStorage.setItem('token', newToken);
                error.config.headers['Authorization'] = `Bearer ${newToken}`;
                return axiosInstance(error.config);
            } else {
                window.location.href = '/signin';
            }
        }
        return Promise.reject(error);
    }
);

// -----------------------------------------------------------
/*CRUD generique
*
*/
export const getBlobFile = (endpoint, data) => {
    return axiosInstance.post(endpoint, data, {
        headers: HeaderFile,
        responseType: 'blob', // Set the responseType to 'blob'
    });
};

export const get = (endpoint) => {
    return axiosInstance({ url: host + '/' + endpoint, method: 'GET', headers: Header })
}

export const getElement = (endpoint) => {
    return axiosInstance({ url: host + '/' + endpoint, method: 'GET', headers: Header })
}


export const createElement = (endpoint, data, sendFile = false) => {
    var headerSend = Header;
    if (sendFile) {
        headerSend = { ...Header, 'Content-Type': 'multipart/form-data' }
    }
    return axiosInstance({ url: host + '/' + endpoint, method: 'POST', headers: headerSend, data: sendFile ? data : JSON.stringify(data) });
}

export const searchElement = (endpoint, data) => {
    return axiosInstance({ url: host + '/' + endpoint, method: 'POST', headers: Header, data: JSON.stringify(data) });
}

export const getElementById = (endpoint, id) => {
    return axiosInstance({ url: host + '/' + endpoint + '/' + id, method: 'GET', headers: Header });
}

export const updateElementById = (endpoint, id, data, sendFile = false) => {
    var headerSend = Header;
    if (sendFile) {
        headerSend = { ...Header, 'Content-Type': 'multipart/form-data' }
    }
    return axiosInstance({ url: host + '/' + endpoint + '/' + id + '/', method: 'PUT', headers: headerSend, data: sendFile ? data : JSON.stringify(data) });
}

export const changeElementById = (endpoint, id, data, sendFile = false) => {
    var headerSend = Header;
    if (sendFile) {
        headerSend = { ...Header, 'Content-Type': 'multipart/form-data' }
    }
    return axiosInstance({ url: host + '/' + endpoint + '/' + id + '/', method: 'PATCH', headers: headerSend, data: sendFile ? data : JSON.stringify(data) });
}

export const deleteElement = (endpoint, id) => {
    return axiosInstance({ url: host + '/' + endpoint + '/' + id + '/', method: 'DELETE', headers: Header });
}