import React, { useState } from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../configs/Firebase";
import PulseLoader from "react-spinners/PulseLoader";

export default function SignUp() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [acceptedCondition, setAcceptedCondition] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    if (validate(email)) {
      setLoading(true);
      event.preventDefault();
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await updateProfile(auth.currentUser, { displayName: username });

        await setDoc(doc(db, "users", userCredential.user.uid), {
          username: username,
          email: email,
          userId: userCredential.user.uid,
          timestamp: new Date(),
        });

        navigate("/form");
      } catch (error) {
        setError(true);
        console.log(error.message);
        setLoading(false);
      }
    }
  };

  const validate = (email) => {
    if (!email) {
      return false
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return false
    }

    return true
  }

  const openCondition = () => {
    var url = 'https://alkhayri.com/terms-conditions';
    setTimeout(() => {
      window.open(url, "_blank");
    });
  }

  const hadleAcceptedCondition = () => {
    setAcceptedCondition(true);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-8 bg-white rounded shadow">
        <h1 className="text-3xl font-bold text-red-500">AL KHAYRI</h1>
        <h2 className="text-xl font-bold">Créer un compte</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {email.length > 0 && !validate(email) && (<div className="color-danger">
            <p>Email incorrect.</p>
          </div>)}
          <input
            type="text"
            placeholder="Créer un Pseudo"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full p-2 border border-gray-500 rounded"
          />
          <input
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
          <input
            type="password"
            placeholder="Confirmer mot de passe"
            //value={password}
            //onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />

          <div className="mt-2">
            <input type="checkbox" id="acceptedCondition" onChange={hadleAcceptedCondition} />
            <label htmlFor="acceptedCondition" className="ml-2 text-sm font-bold">
              J'ai lu et j'accepte les <span className="underline" onClick={openCondition}>conditions générales d'utilisation..</span>
            </label>
          </div>
          <button type="submit" className="w-full py-2 text-white bg-red-500 rounded" disabled={!acceptedCondition}>
            {loading ? <PulseLoader color='white' size={15} /> : 'Confirmer'}
          </button>
        </form>
        {error && <p className="text-red-500">Une erreur est survenu lors de la creation</p>}

        <div className="text-center">
          <a href="/signin" className="text-gray-800">Déjà un compte ? <span className="text-red-500 underline"> Se connecter </span></a>
        </div>
      </div>
    </div>
  );
}
