// Helper functions to map API responses to formData
export const mapProfile = (profile) => ({
    name: profile.name || '',
    bio: profile.bio || '',
    birthDate: profile.birth_date 
        ? new Date(profile.birth_date).toISOString().slice(0, 10).split("-").reverse().join("/") 
        : '',
    gender: profile.gender || '',
    birthCountry: profile.birth_country || '',
    residenceCountry: profile.residence_country || '',
    residenceCity: profile.residence_city || '',
    religion: profile.religion || '',
    educationLevel: profile.education_level || '',
    profession: profile.profession || '',
    maritalStatus: profile.marital_status || '',
    numberOfChildren: profile.number_of_children || 0,
    jobStatus: profile.job_status || '',
});

export const mapCriteria = (criteria) => ({
    preferredAgeRange: criteria.preferred_age_range || [],
    preferredNationality: criteria.preferred_nationality || [],
    preferredReligions: criteria.preferred_religions || [],
    preferredResidenceCountry: criteria.preferred_residence_country || [],
    preferredResidenceCity: criteria.preferred_residence_city || [],
    preferredJobStatus: criteria.preferred_job_status || [],
    preferredMaritalStatus: criteria.preferred_marital_status || ['single'],
    preferredEducationLevel: criteria.preferred_education_level || [],
    preferredNumberOfChildren: criteria.preferred_number_of_children || ['0'],
});

export const mapOtherInformation = (otherInformations) => ({
    otherInformationResponses: otherInformations.map(info => ({
        otherInformationId: info.other_information.id,
        id: info.id,
        value: info.value,
    })),
});
