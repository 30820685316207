import React, { useState } from 'react';
import Select from 'react-select';
import { allCountries } from 'country-region-data';
import ButtonSelect from '../../utilities/interface/ButtonSelect';
import CountrySelect from '../../utilities/interface/CountrySelect';
import ButtonSelectMultiple from '../../utilities/interface/ButtonSelectMultiple';

const PrimaryInfo = ({ handleChange, handleSelectChange, formData, criteriaOption, profileCriteria, setProfileCriteria, validationErrors }) => {
    const [regionOptions, setRegionOptions] = useState([]);
    const [loadingRegions, setLoadingRegions] = useState(false);

    const handleCountryChange = (name, selectedOption) => {
        handleSelectChange(name, selectedOption.value);

        if (name === 'residenceCountry') {
            setRegionOptions(selectedOption?.regions || []);
        }
    };

    const handleRegionChange = (selectedOption) => {
        handleSelectChange('residenceCity', selectedOption?.value);
    };

    const valueOption = (option, val) => {
        return option.find(op => op.value === val);
    };

    return (
        <div className="p-8 bg-white rounded-md shadow-md">
            <div className="mb-4">
                <p>Avant de commencer, nous tenons à préciser qu'il s'agit d'un projet très sérieux qui vise à mettre en relation des personnes qui cherchent à se marier.</p>
            </div>
            <h2 className="text-xl font-bold mb-4">Informations personnelle</h2>
            <form>

                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium">
                        Pseudo <span className="text-gray-500">*</span>
                    </label>
                    <input type="text" name="name" placeholder="Nato" value={formData.name} onChange={handleChange} className="mt-1 p-2 block w-full border rounded-md" />
                    {validationErrors.name && <span className="text-red-500">{validationErrors.name}</span>}
                </div>
                <div className="mb-5">
                    <label htmlFor="birthDate" className="block text-sm font-medium">
                        Date de naissance <span className="text-gray-500">*</span>
                    </label>
                    <input type="text" name="birthDate" placeholder="09/02/1975" value={formData.birthDate} onChange={handleChange} className="mt-1 p-2 block w-full border rounded-md" />
                    {validationErrors.birthDate && <span className="text-red-500">{validationErrors.birthDate}</span>}
                </div>
                <div className="mb-5">
                    <label htmlFor="gender" className="block text-sm font-medium">
                        Sexe <span className="text-gray-500">*</span>
                    </label>
                    <ButtonSelect
                        options={[
                            { value: "male", label: "Homme" },
                            { value: "female", label: 'Femme' },
                        ]}
                        setSelectedOption={(val) => handleSelectChange('gender', val)}
                        selectedOption={formData.gender}
                    />
                    {validationErrors.gender && <span className="text-red-500">{validationErrors.gender}</span>}
                </div>
                <div className="mb-4">
                    <label htmlFor="birthCountry" className="block text-sm font-medium">
                        Pays de naissance ou origine <span className="text-gray-500">*</span>
                    </label>
                    <CountrySelect
                        value={formData.birthCountry}
                        name="birthCountry"
                        handleCountryChange={(val) => handleCountryChange('birthCountry', val)}
                    />
                    {validationErrors.birthCountry && <span className="text-red-500">{validationErrors.birthCountry}</span>}
                </div>
                <div className="flex items-center my-10">
                    <span className="mr-4 text-gray-500">Situation professionnelle</span>
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="mb-4">
                    <label htmlFor="jobStatus" className="block text-sm font-medium">
                        Activité <span className="text-gray-500">*</span>
                    </label>
                    <ButtonSelect
                        options={[
                            { value: "student", label: "Étudiant (e)" },
                            { value: "working", label: 'Travailleur (euse)' },
                            { value: "unemployed", label: 'Sans emploi / En recherche d\'emploi' },
                        ]}
                        setSelectedOption={(val) => handleSelectChange('jobStatus', val)}
                        selectedOption={formData.jobStatus}
                    />
                    {validationErrors.jobStatus && <span className="text-red-500">{validationErrors.jobStatus}</span>}
                </div>
                <div className="mb-4">
                    <label htmlFor="profession" className="block text-sm font-medium">
                        Profession
                    </label>
                    <input type="text" name="profession" placeholder="Ingénieur agronome" value={formData.profession} onChange={handleChange} className="mt-1 p-2 block w-full border rounded-md" />
                </div>
                <div className="mb-4">
                    <label htmlFor="educationLevel" className="block text-sm font-medium">
                        Niveau d'étude <span className="text-gray-500">*</span>
                    </label>
                    <ButtonSelect
                        options={[
                            { value: "before-bac", label: "Avant BAC" },
                            { value: "bac-plus", label: 'BAC ou BAC + 1 ou BAC + 2' },
                            { value: "advenced", label: 'Études Supérieures Avancées' },
                            { value: "other", label: 'Autre' }
                        ]}
                        setSelectedOption={(val) => handleSelectChange('educationLevel', val)}
                        selectedOption={formData.educationLevel}
                    />
                    {validationErrors.educationLevel && <span className="text-red-500">{validationErrors.educationLevel}</span>}
                </div>
                <div className="mb-4">
                    <label htmlFor="residenceCountry" className="block text-sm font-medium">
                        Pays de résidence <span className="text-gray-500">*</span>
                    </label>
                    <CountrySelect
                        value={formData.residenceCountry}
                        name="residenceCountry"
                        handleCountryChange={(val) => handleCountryChange('residenceCountry', val)}
                    />
                    {validationErrors.residenceCountry && <span className="text-red-500">{validationErrors.residenceCountry}</span>}
                </div>
                <div className="mb-4">
                    <label htmlFor="residenceCity" className="block text-sm font-medium">
                        Ville de résidence <span className="text-gray-500">*</span>
                    </label>
                    <Select
                        value={valueOption(regionOptions, formData.residenceCity)}
                        placeholder="Choisir"
                        name="residenceCity"
                        options={regionOptions}
                        onChange={handleRegionChange}
                        isLoading={loadingRegions}
                        isClearable
                        className="mt-1"
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => null }}
                    />
                    {validationErrors.residenceCity && <span className="text-red-500">{validationErrors.residenceCity}</span>}
                </div>
                <div className="flex items-center my-10">
                    <span className="mr-4 text-gray-500">Situation matrimoniale</span>
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="mb-4">
                    <label htmlFor="maritalStatus" className="block text-sm font-medium">
                        Situation <span className="text-gray-500">*</span>
                    </label>
                    <ButtonSelect
                        options={[
                            { value: "single", label: "Célibataire" },
                            { value: "married", label: 'Marié', hide: formData.gender === 'female' },
                            { value: "divorced", label: 'Divorcé(e)' },
                            { value: "widowed", label: 'Veuf(ve)' }
                        ]}
                        setSelectedOption={(val) => handleSelectChange('maritalStatus', val)}
                        selectedOption={formData.maritalStatus}
                    />
                    {validationErrors.maritalStatus && <span className="text-red-500">{validationErrors.maritalStatus}</span>}
                </div>
                <div className="mb-4">
                    <label htmlFor="numberOfChildren" className="block text-sm font-medium">
                        Nombre d'enfants <span className="text-gray-500">*</span>
                    </label>
                    <input type="number" name="numberOfChildren" value={formData.numberOfChildren} onChange={handleChange} className="mt-1 p-2 block w-full border rounded-md" />
                    {validationErrors.numberOfChildren && <span className="text-red-500">{validationErrors.numberOfChildren}</span>}
                </div>
                <div className="flex items-center my-10">
                    <span className="mr-4 text-gray-500">Religion</span>
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="mb-4">
                    <ButtonSelect
                        options={[
                            { value: "muslim", label: "Musulman (e)" },
                            { value: "christian", label: 'Chrétien (e)' },
                            { value: "other", label: 'Autre' },
                        ]}
                        setSelectedOption={(val) => handleSelectChange('religion', val)}
                        selectedOption={formData.religion}
                    />
                    {validationErrors.religion && <span className="text-red-500">{validationErrors.religion}</span>}
                </div>
                <div className="flex items-center my-10">
                    <span className="mr-4 text-gray-500">Atouts</span>
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="mb-4">
                    <label htmlFor="criteria" className="block text-sm font-medium">
                        Choisissez trois critères qui vous définissent le plus
                    </label>
                    <ButtonSelectMultiple
                        maxLength={3}
                        options={criteriaOption}
                        setSelectedOption={setProfileCriteria}
                        selectedOption={profileCriteria}
                    />
                </div>
            </form>
        </div>
    );
};

export default PrimaryInfo;