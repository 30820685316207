import 'chart.js/auto';
import { Fragment, useEffect, useState } from "react";
import { getElement } from '../configs/http-connect';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { getAge } from '../utilities/calcul';
import { translate } from '../utilities/lng';
import PulseLoader from 'react-spinners/PulseLoader';
import { MdOutlineLockPerson } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';


function ProfileDetail({ profile, setDisplayDetails, startChat, readOnly, unblocked }) {
    const [otherInfos, setOtherInfos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initMatchError, setInitMatchError] = useState(false);
    const navigate = useNavigate();

    const [userProfile, setUserProfile] = useState(profile);

    const { profile_id } = useParams();

    const matchProfile = (profile) => {
        setLoading(true);
        getElement('candidate/match_new_profile/' + profile.id).then(res => {
            if (startChat) {
                startChat({
                    userId: profile.created_by.uid,
                    username: profile.name,
                    email: profile.created_by.username,
                });
            }
            else {
                alert('Match réussit avec succés. Allez dans mes matchs pour entrez en contact avec le profile');
                navigate("/home");
            }

            setLoading(false);
        }).catch(err => {
            setLoading(false);
            setInitMatchError(true);
            console.log('Error ::: ', err);
        });
    }

    const getProfileData = () => {
        getElement('candidate/profile_by_id/' + profile_id).then(res => {
            setUserProfile(res.data);
            getOtherInformation(res.data.id);
        }).catch(err => {
            console.log('Error ::: ', err);
        });
    };

    const getOtherInformation = (id) => {
        getElement('candidate/other_information_profile/' + id).then(res => {
            setOtherInfos(res.data);
        }).catch(err => {
            console.log('Error ::: ', err);
        });
    };


    useEffect(() => {
        if (profile) {
            setUserProfile(profile)
            getOtherInformation(profile.id);
        } else {
            if (profile_id) {
                getProfileData();
            }
        }
    }, [profile, profile_id]);

    const close = () => {
        if (profile_id) {
            navigate("/home");
        } else {
            setDisplayDetails(false);
        }

    }

    const contactProfile = () => {
        matchProfile(userProfile);
    }


    const openInNewTab = (url) => {
        setTimeout(() => {
            window.open(url, "_blank");
            if (!window.open(url, "_blank")) {
                window.location.href = url;
            }
        });
    }


    const getCheckoutUrl = () => {
        setLoading(true);
        getElement('candidate/checkout-unblocked-profile/' + profile.id).then(res => {
            openInNewTab(res.data.url);
        }).catch(err => {
            console.log('Error ::: ', err);
            setLoading(false);
        });
    }

    const unblockedProfiles = () => {
        getCheckoutUrl()
    }


    return (
        <div>

            <div className="my-2 flex items-center">
                <FaArrowLeft color="red" className="mr-4 cursor-pointer" onClick={close} />
                <strong>Infos de {userProfile?.name}  </strong> {!userProfile?.payed && <MdOutlineLockPerson color="orange" className='ml-2' />}
            </div>
            <div className="space-y-4 px-3">


                {(!userProfile?.payed && !unblocked) ?
                    <small className='text-[#3c6c70]'>{userProfile?.name} n'a pas encore activé son profil. Pour entrez en contact avec {userProfile?.gender == 'male' ? 'lui' : 'elle'}, débloquez son profil dès maintenant. Une fois débloqué,  {userProfile?.gender == 'male' ? ' il' : 'elle'} pourra communiquer uniquement avec vous.
                    </small> :
                    <small>Nous vous invitons à lire les informations supplémentaires de {userProfile?.name}.</small>}
                <div className="my-2">
                    <strong>Informations personnelles</strong>
                </div>

                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Bio</p>
                    <p>{userProfile?.bio}</p>
                </div>

                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Age</p>
                    <p> {getAge(userProfile?.birth_date)} ans </p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Pays de naissance </p>
                    <p>{userProfile?.birth_country} </p>
                </div>

                {!readOnly &&
                    <Fragment>
                        {(userProfile?.payed || unblocked) ? <button type="button" onClick={contactProfile} className="border bg-gray-50 border-gray text-gray px-2 py-2 rounded-md flex items-center my-2">
                            {unblocked ? 'Contactez ' : 'Matchez avec '} {userProfile?.name} {loading ? <PulseLoader color='red' className="ml-4" /> : <FaArrowRight color="red" className="ml-4" />}
                        </button>
                            :
                            <button type="button" onClick={unblockedProfiles} className="border bg-gray-50 border-gray text-gray px-2 py-2 rounded-md flex items-center my-2">
                                Débloquer {userProfile?.name} 10€ {loading ? <PulseLoader color='red' className="ml-4" /> : <FaArrowRight color="red" className="ml-4" />}
                            </button>}
                        {initMatchError && <small className='text-red-500'> Désolé, vous ne pouvez pas lancer un nouveau match</small>}
                    </Fragment>
                }
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Religion </p>
                    <p> {translate[userProfile?.religion]}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Pays de résidence</p>
                    <p> {userProfile?.residence_country}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Ville de résidence</p>
                    <p> {userProfile?.residence_city}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Niveau d'éducation </p>
                    <p>{translate[userProfile?.education_level]}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Situation professionnelle </p>
                    <p>{translate[userProfile?.job_status]}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Profession </p>
                    <p>{userProfile?.profession ?? ' _-_  '}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Statut matrimonial </p>
                    <p>{translate[userProfile?.marital_status]} </p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Nombre d'enfants </p>
                    <p>{userProfile?.number_of_children} </p>
                </div>
                <div className="my-2">
                    <strong>Informations supplémentaires </strong>
                </div>


                {otherInfos.map((otherInfo, index) => {
                    const emailPattern = /[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,}/;
                    const containsEmail = emailPattern.test(otherInfo.value);

                    return !containsEmail && (
                        <div key={index} className="my-1">
                            <p className="text-gray-500 text-sm font-semibold">{otherInfo.other_information.question_name}</p>
                            <p>{otherInfo.value}</p>
                        </div>
                    );
                })}

                {!readOnly &&
                    <Fragment>
                        {(userProfile?.payed || unblocked) ? <button type="button" onClick={contactProfile} className="border bg-gray-50 border-gray text-gray px-2 py-2 rounded-md flex items-center my-2">
                            {unblocked ? 'Contactez ' : 'Matchez avec '} {userProfile?.name} {loading ? <PulseLoader color='red' className="ml-4" /> : <FaArrowRight color="red" className="ml-4" />}
                        </button>
                            :
                            <button type="button" onClick={unblockedProfiles} className="border bg-gray-50 border-gray text-gray px-2 py-2 rounded-md flex items-center my-2">
                                Débloquer {userProfile?.name} 10€ {loading ? <PulseLoader color='red' className="ml-4" /> : <FaArrowRight color="red" className="ml-4" />}
                            </button>}
                        {initMatchError && <small className='text-red-500'> Désolé, vous ne pouvez pas lancer un nouveau match</small>}
                    </Fragment>
                }
            </div>
        </div>
    );
}

export default ProfileDetail;