import 'chart.js/auto';
import { Fragment, useEffect, useState } from "react";
import { getElement } from '../configs/http-connect';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { getAge } from '../utilities/calcul';
import { translate } from '../utilities/lng';
import PulseLoader from 'react-spinners/PulseLoader';
import { MdOutlineLockPerson } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';


function EmailProfile() {
    const [userProfile, setUserProfile] = useState();
    const [otherInfos, setOtherInfos] = useState([]);
    const [loading, setLoading] = useState(false);
    const { profile_email } = useParams();



    const getProfileData = () => {
        getElement('candidate/profile_by_email/' + profile_email).then(res => {
            setUserProfile(res.data);
            getOtherInformation(res.data.id);
        }).catch(err => {
            console.log('Error ::: ', err);
        });
    };

    const getOtherInformation = (id) => {
        getElement('candidate/other_information_profile/' + id).then(res => {
            setOtherInfos(res.data);
        }).catch(err => {
            console.log('Error ::: ', err);
        });
    };


    useEffect(() => {
        if (profile_email) {
            getProfileData();
        }
    }, [profile_email]);



    return (
        <div> 

           
            <div className="space-y-4 px-3">


                
                <div className="my-2">
                    <strong>Informations personnelles</strong>
                </div>

                <div className="my-1">
                    <p className="text-red-500 text-xl font-semibold ">Lien</p>
                    <p className="text-red-500 text-xl underline">https://alkhayri.com/profile/{userProfile?.id}</p>
                </div>

                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Bio</p>
                    <p>{userProfile?.bio}</p>
                </div>

                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Age</p>
                    <p> {getAge(userProfile?.birth_date)} ans </p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Pays de naissance </p>
                    <p>{userProfile?.birth_country} </p>
                </div>


                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Religion </p>
                    <p> {translate[userProfile?.religion]}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Pays de résidence</p>
                    <p> {userProfile?.residence_country}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Ville de résidence</p>
                    <p> {userProfile?.residence_city}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Niveau d'éducation </p>
                    <p>{translate[userProfile?.education_level]}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Situation professionnelle </p>
                    <p>{translate[userProfile?.job_status]}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Profession </p>
                    <p>{userProfile?.profession ?? ' _-_  '}</p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Statut matrimonial </p>
                    <p>{translate[userProfile?.marital_status]} </p>
                </div>
                <div className="my-1">
                    <p className="text-gray-500 text-sm font-semibold">Nombre d'enfants </p>
                    <p>{userProfile?.number_of_children} </p>
                </div>
                <div className="my-2">
                    <strong>Informations supplémentaires </strong>
                </div>


                {otherInfos.map((otherInfo, index) => {
                    const emailPattern = /[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,}/;
                    const containsEmail = emailPattern.test(otherInfo.value);

                    return !containsEmail && (
                        <div key={index} className="my-1">
                            <p className="text-gray-500 text-sm font-semibold">{otherInfo.other_information.question_name}</p>
                            <p>{otherInfo.value}</p>
                        </div>
                    );
                })}


            </div>
        </div>
    );
}

export default EmailProfile;