import { BsArrowRightShort } from 'react-icons/bs';
import coeur from '../../assets/img/coeur.png';
import heart from '../../assets/img/heart.svg';
import search from '../../assets/img/search.svg';
import virgule from '../../assets/img/virgule.svg';
import map from '../../assets/img/map.svg';
import { useNavigate } from "react-router-dom"

function LandingPage(props) {
    const navigate = useNavigate()

    return (
        <>
            <div className='bg-alkhayri-bg bg-cover bg-no-repeat'>
                <header className="bg-white bg-opacity-75 h-[50px] flex flex-row justify-between items-center px-8">
                    <div className='text-[26px] font-bold text-[#BF2228] ml-3'>
                        Alkhayri
                    </div>
                    <button className='text-[14px] bg-[#BF2228] text-white px-3 p-1 mr-3 rounded-lg flex flex-row items-center' onClick={() => navigate('home')}>
                        Rejoignez-nous <BsArrowRightShort />
                    </button>
                </header>
                <div className='bg-[#BF2228] bg-opacity-25 w-screen px-10 min-h-screen pb-[50px]'>
                    <div className='pt-[30px] md:pt-[70px]'>

                        <div className='py-[10px] flex flex-row justify-between'>
                            <div>
                                <div className='text-4xl md:text-6xl font-bold text-white'>
                                    Trouvez votre âme sœur et<br /> donnez vie à votre projet de mariage
                                </div>
                                <div className='text-xl text-white'>Conçu pour les adultes en quête de relations sérieuses et d'un projet de mariage heureux et durable.</div>
                                <button className='text-[14px] bg-[#BF2228] text-white p-3 my-4 mr-3 rounded-lg flex flex-row items-center' onClick={() => navigate('home')}>
                                    Rejoignez-nous <BsArrowRightShort />
                                </button>
                            </div>
                            <div className='flex-row md:flex hidden'>
                                {/*<img src={couple} className="object-cover w-[160px] h-[210px] rounded-xl -rotate-[18deg]" alt="Couple" />*/}
                                <img src={coeur} className="object-cover w-[160px] h-[210px] rotate-[8deg] rounded-xl mt-[60px]" alt="Coeur" width={250} height={350} />
                            </div>
                        </div>

                        <div className='py-[30px] md:flex flex-row justify-between bg-white bg-opacity-75 rounded-xl my-[15px] md:-mt-[50px] items-center'>
                            <div className='flex flex-col justify-center py-5'>
                                <img src={search} className="h-[38px] md:w-[48px] self-center" alt="Search" />
                                <div className='text-[18px] font-bold text-[#BF2228] text-center'>
                                    Anonyme
                                </div>
                                <p className='text-center ml-3'>Rencontrez de manière confidentielle votre future époux(se).</p>
                            </div>
                            <div className='flex flex-col justify-center py-5'>
                                <img src={map} className="h-[38px] md:w-[32px] self-center" alt="Map" />
                                <div className='text-[18px] font-bold text-[#BF2228] text-center'>
                                    International
                                </div>
                                <p className='text-center'>Nos services sont disponibles dans plusieurs pays.</p>
                            </div>
                            <div className='flex flex-col justify-center py-5'>
                                <img src={heart} className="h-[38px] md:w-[32px] self-center" alt="Heart" />
                                <div className='text-[18px] font-bold text-[#BF2228] text-center'>
                                    Passionnant
                                </div>
                                <p className='text-center mr-3'>Créez des souvenirs inoubliables avec votre partenaire.</p>
                            </div>
                        </div>
                        <div>
                            <div className='text-[18px] text-center font-bold text-white py-8'>Témoignages</div>
                            <div className='md:flex flex-row justify-between'>
                                <div className='bg-white bg-opacity-50 p-3 rounded-xl my-[10px] md:w-[30%]'>
                                    <img src={virgule} className="w-[45px] float-right" alt="Virgule" />
                                    <span className='text-[#BF2228] font-bold'>Yacine</span>
                                    <p className='italic text-[14px]'>
                                        “Grâce à Alkhayri, j'ai trouvé l'amour de ma vie et nous préparons notre mariage. Merci infiniment !”
                                    </p>
                                </div>
                                <div className='bg-white bg-opacity-50 p-3 rounded-xl my-[10px] md:w-[30%]'>
                                    <img src={virgule} className="w-[45px] float-right" alt="Virgule" />
                                    <span className='text-[#BF2228] font-bold'>Mor</span>
                                    <p className='italic text-[14px]'>
                                        “Sdsk Alkhayri a rendu possible une rencontre que je pensais impossible. Une expérience incroyable !”
                                    </p>
                                </div>
                                <div className='bg-white bg-opacity-50 p-3 rounded-xl my-[10px] md:w-[30%]'>
                                    <img src={virgule} className="w-[45px] float-right" alt="Virgule" />
                                    <span className='text-[#BF2228] font-bold'>Hassan Ndoye</span>
                                    <p className='italic text-[14px]'>
                                        “Un service exceptionnel qui m'a aidé à trouver ma moitié. Je recommande vivement !”
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Motivations Section */}
            <div className='bg-white bg-opacity-90 p-10 rounded-3xl mt-10 mx-10 shadow-lg'>
                <div className='text-3xl text-[#BF2228] font-extrabold text-center mb-6'>
                    Les Motivations de la création d'ALKHAYRI
                </div>
                <p className='text-lg text-justify text-[#333] leading-8'>
                    Dans un monde où les valeurs traditionnelles et la pudeur semblent souvent reléguées au second plan, les Africains de la diaspora font face à des défis uniques lorsqu'il s'agit de trouver un partenaire de vie. Consciente des obstacles et des influences négatives qui pervertissent de nombreuses applications de rencontre, Tata Habibi, une Sénégalaise au grand cœur, a décidé de mettre son savoir et son expérience au service de sa communauté.
                </p>
                <p className='text-lg text-justify text-[#333] leading-8 mt-4'>
                    Tata Habibi a observé avec tristesse comment les plateformes existantes manquaient de sérieux, laissant souvent les utilisateurs vulnérables face à des intentions peu sincères. Elle a également ressenti le besoin profond de créer un espace où la pudeur, le respect et les valeurs familiales sont au centre de chaque interaction. C'est ainsi qu'est née l'idée de ALKHAYRI, une plateforme dédiée aux individus sérieux et matures, aspirant à trouver un(e) époux(se) avec des intentions nobles et sincères.
                </p>
                <p className='text-lg text-justify text-[#333] leading-8 mt-4'>
                    ALKHAYRI n'est pas simplement une application de rencontre ; c'est un sanctuaire pour ceux qui cherchent à construire des relations solides et authentiques. Tata Habibi a investi tout son amour et son expertise pour offrir une solution fiable et respectueuse, adaptée aux attentes et aux besoins des Africains de la diaspora.
                </p>
                <p className='text-lg text-justify text-[#333] leading-8 mt-4'>
                    En vous présentant ALKHAYRI, nous espérons que vous trouverez non seulement un partenaire, mais également un compagnon de vie avec qui partager des valeurs communes et construire un avenir radieux. Bienvenue dans une nouvelle ère de rencontres, où la sincérité et le respect priment, et où chaque utilisateur est traité avec dignité et honneur.
                </p>
                <button className='mt-10 text-[14px] bg-[#BF2228] text-white px-3 p-1 mr-3 rounded-lg flex flex-row items-center' onClick={() => navigate('home')}>
                    Rejoignez-nous <BsArrowRightShort />
                </button>
            </div>

            {/* Additional Header Section */}
            <header className="bg-[#BF2228] text-white text-center py-6 mt-10">
                <h2 className="text-3xl font-extrabold">Explorez les Fonctionnalités de ALKHAYRI</h2>
                <p className="text-lg mt-2">Découvrez comment notre plateforme peut vous aider à trouver votre partenaire idéal.</p>
            </header>
        </>
    );
}

export default LandingPage;
