import React, { useState } from 'react';

const ImportanceInput = ({ criteria, handleSelectChange }) => {

  const initialImportance = Math.round(250 / criteria.length);
  const [importances, setImportances] = useState(criteria.reduce((acc, criterion) => {
    acc[criterion.value] = initialImportance;
    return acc;
  }, {}));

  const handleChange = (id, value) => {
    const newValue = parseInt(value);
    if (isNaN(newValue) || newValue <= 0) return;

    const delta = newValue - importances[id];
    const otherCriteria = criteria.filter(c => c.value !== id && importances[c.value] > 0);

    const totalValue = Object.values(importances).reduce((acc, val) => acc + val, 0) + delta;
    const updatedImportances = { ...importances, [id]: newValue };

    if (totalValue >= 200) {
      const totalDelta = (totalValue - 250) / otherCriteria.length;
      otherCriteria.forEach(c => {
        updatedImportances[c.value] -= Math.round(totalDelta);
      });
    }

    setImportances(updatedImportances);
    handleSelectChange('partnerCriteria', updatedImportances)
  };

  return (
    <div className="grid grid-cols-1 gap-4">
      <small>Glisser pour modifier </small>
      {criteria.map(criterion => (
        <div key={criterion.value} className="flex items-center">
          <label htmlFor={criterion.value} className="w-24">{criterion.label}:</label>
          <input
            type="range"
            id={criterion.value}
            name={criterion.value}
            min="0"
            max="100"
            value={importances[criterion.value]}
            onChange={(e) => handleChange(criterion.value, e.target.value)}
            className="w-64 custom-range-slider appearance-none bg-red-100"
            style={{ borderRadius: '9999px', height: '1.5rem', outline: 'none', opacity: '0.7', transition: 'opacity 0.2s' }}
          />
          <span className="ml-2">{importances[criterion.value]}%</span>
        </div>
      ))}
    </div>
  );
};

export default ImportanceInput;