import React, { useState } from 'react';


const ButtonSelectMultiple = ({ options, selectedOption, setSelectedOption, maxLength }) => {

    const handleButtonClick = (value) => {
        let updatedSelectedOption;
        if (selectedOption.includes(value)) {
            updatedSelectedOption = selectedOption.filter(item => item !== value);
        } else if (maxLength && selectedOption.length >= maxLength) {
            updatedSelectedOption = selectedOption;
        } else {
            updatedSelectedOption = [...selectedOption, value];
        }
        setSelectedOption(updatedSelectedOption);
    };

    return (
        <div className="flex flex-wrap gap-2 rounded my-2 max-w-full">
            {options.map((option, index) => 
                !option.hide && <div
                    key={index}
                    onClick={() => handleButtonClick(option.value)}
                    className={`cursor-pointer px-3 py-1 border border-red-200 rounded-md ${selectedOption.includes(option.value) ? 'bg-red-500 text-white' : 'bg-transparent text-neutral-600'}`}
                >
                    {option.label}
                </div>
            )}
        </div>
    );
};

export default ButtonSelectMultiple;