import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './views/landing-page';
import CandidateForm from './views/form';
import HomeSpace from './views/espace/Home.js';
import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import ChatSpace from './views/espace/Chat.js';
import Main from './views/espace/Main.js';
import TermsAndConditions from './views/landing-page/TermsAndConditions.js';
import ProfileDetail from './components/ProfileDetail.js';
import EmailProfile from './components/EmailProfile.js';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="form/" element={<CandidateForm />} />
      <Route path="home/" element={<Main />} />
      <Route path="profile/:profile_id" element={<ProfileDetail />} />
      <Route path="profile/email/:profile_email" element={<EmailProfile />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/chat" element={<ChatSpace />} />
      <Route path="/terms-conditions" element={<TermsAndConditions />} />

    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();